import React from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import Container from '../../components/global/Container';
import Heading from '../../components/global/Heading';
import RichText from '../../components/global/RichText';
import { useBreakpoint } from '../../app/providers/breakpointProvider';
import ModuleStyle from './module.style';
import Separator from '../../components/global/Separator';

export const getServerSideProps = async () => {
  return {
    props: {},
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const DoubleTextModule = ({
  left,
  right,
  wlModule,
  borderTop,
  borderBottom,
  ...p
}) => {
  const sbs = useBreakpoint().equalsOrUp('desktopSmall');
  const firstElem = (
    <>
      {!!left?.title && <Heading title={left?.title} />}
      {!!left?.text?.content && <RichText content={left?.text.content} />}
    </>
  );
  const secondElem = (
    <>
      {!!right?.title && <Heading title={right?.title} />}
      {!!right?.text?.content && <RichText content={right?.text.content} />}
    </>
  );
  if (sbs) {
    const spacing = {
      layout: false,
      pl: '0',
      pr: '0',
      mt: '0',
      mb: '0',
      pt: undefined,
      pb: undefined,
    };
    return (
      <ModuleStyle grid gap="30px" columns="1fr 1fr" as="div">
        <Container flex col gap="20px" wlModule={wlModule} {...spacing} {...p}>
          {borderTop && <Separator className="separator-top" />}
          {firstElem}
          {borderBottom && <Separator className="separator-bottom" />}
        </Container>
        <Container flex col gap="20px" {...spacing} {...p}>
          {borderTop && <Separator className="separator-top" />}
          {secondElem}
          {borderBottom && <Separator className="separator-bottom" />}
        </Container>
      </ModuleStyle>
    );
  } else {
    const spacing = {
      xPaddingAsMargin: true,
    };
    return (
      <>
        <ModuleStyle
          flex
          col
          gap="20px"
          wlModule={wlModule}
          {...spacing}
          {...p}
        >
          {borderTop && <Separator className="separator-top" />}
          {firstElem}
          <Separator className="separator-bottom" />
        </ModuleStyle>
        <ModuleStyle flex col gap="20px" {...spacing} {...p}>
          {secondElem}
          {borderBottom && <Separator className="separator-bottom" />}
        </ModuleStyle>
      </>
    );
  }
};

export default DoubleTextModule;

registerModule({ DoubleText: Self });
