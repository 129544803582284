import styled from '@emotion/styled';
import Container from '../../components/global/Container';

export default styled(Container)`
  --main-mt: var(--mt);
  --main-mb: var(--mb);
  .separator-bottom {
    position: absolute;
    top: 100%;
    margin: calc(var(--main-mb) / 2) 0 0;
  }
  .separator-top {
    position: absolute;
    bottom: 100%;
    margin: 0 0 calc(var(--main-mt) / 2);
  }
`;
